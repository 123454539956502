@import 'styles/includes.scss';

.Root {
}

.TitleRow {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DesktopButton {
    display: none;

    @include media(m) {
        display: block;
        margin-bottom: 40px;
    }
}

.Title {
    @include h2-headline;
    margin-bottom: 16px;

    @include media(m) {
        margin-bottom: 24px;
    }
}

.Items {
    display: flex;
    flex-wrap: wrap;

    &--RespSlider {
        flex-wrap: nowrap;
        overflow-x: scroll;
        width: calc(100% + 48px);
        margin-left: -24px;
    }

    @include media(m) {
        width: calc(100% + 24px);
        margin-left: -24px;
        flex-wrap: wrap;
        overflow: auto;
    }
}

.Item {
    width: 100%;
    margin-bottom: 24px;

    text-decoration: none;
    border-radius: 2px;
    overflow: hidden;

    &--RespSlider {
        width: 80vw;
        min-width: 80vw;
        margin-bottom: 24px;
        margin-left: 8px;
        text-decoration: none;
        border-radius: 2px;
        overflow: hidden;

        &:first-of-type {
            margin-left: 24px;
        }

        &:last-of-type {
            padding-right: 24px;

            @include media(m) {
                padding-right: 0;
            }
        }
    }

    @include media(m) {
        margin-left: 24px;
        min-width: auto;
        width: calc(50% - 24px);
    }

    @include media(l) {
        width: calc(100% / 3 - 24px);
    }
}

.MobileButton {
    display: block;
    margin-bottom: 80px;

    @include media(m) {
        display: none;
    }
}
