@import 'styles/includes.scss';

.Root {
}

.Button {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    text-decoration: none;
    font-family: $base-font;

    border-radius: 4px;
    background-color: $color-indigo;
    border: none;

    @include navigation-titles;
    color: $color-white;

    @include media(m) {
        width: auto;
        display: inline-flex;
        height: 56px;
        padding: 0 24px;
    }

    &Light {
        background-color: #fff;
        color: rgba(0, 0, 0, 0.9);
    }

    &:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    &:focus {
        border: 1px dotted #fff;
    }
}
