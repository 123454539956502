@import 'styles/includes.scss';

.Root {
    background-color: $color-white;
    padding: 16px;
    min-height: 380px;
}

.ImageWrap {
    width: 100%;
    height: 216px;
    overflow: hidden;
    border-radius: 2px;
    display: block;

    &:hover .Image {
        transform: scale(1.15);
    }
}

.Image {
    width: 100%;
    height: 216px;
    border-radius: 2px;
    object-fit: cover;
    transition: transform .2s;
}

.Title {
    @include h5-headline;
    color: $color-black;
    text-decoration: none;
    margin-top: 8px;
    margin-bottom: 4px;
}

.Text {
    @include body-copy-small;
    color: $color-black;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
